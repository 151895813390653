import * as React from "react";

import { css, withTheme } from "@delight-js/react";
import Row from "../components/layout/Row";
import Cell from "../components/layout/Cell";
import ContentCell from "../components/layout/ContentCell";
import ActionBox from "../components/ActionBox";
import Hero from "../components/Hero";
import ContentBox from "../components/layout/ContentBox";
import { Overline, H3, Subtitle1 } from "../components/texts";
import Button from "../components/Button";
import Link from "../components/Link";
import SharingBar from "../components/SharingBar";
import ldJsonBreadCrumb from "../helpers/ldJsonBreadCrumb";

const ContentCtaBox = withTheme(
  ({ theme, overline, headline, headlineLevel = "h2", subline, link }) => (
    <Cell offset={{ default: 1 / 12 }} span={{ default: 10 / 12 }}>
      <ActionBox>
        <Row
          css={css`
            $py10;
            align-items: center;
          `(theme)}
        >
          <Cell
            offset={{ default: 1 / 10 }}
            span={{ default: 8 / 10, xl: 5 / 10, x2l: 4 / 10 }}
          >
            <Overline>{overline}</Overline>
            <H3 as={headlineLevel} css={css`$my1;`(theme)}>
              {headline}
            </H3>
            <Subtitle1>{subline}</Subtitle1>
          </Cell>
          <Cell
            offset={{ default: 1 / 10, xl: 0, x2l: 1 / 10 }}
            span={{ default: 8 / 10, xl: 3 / 10 }}
            css={css`
              text-align: center;

              @media:x2l {
                text-align: right;
              }
            `}
          >
            <Button
              css={css`
                margin-top: $space-4;
                text-align: center;
              `(theme)}
              as={Link}
              url={link.url}
              title={link.title}
            >
              {link.text}
            </Button>
          </Cell>
        </Row>
      </ActionBox>
    </Cell>
  )
);

const ContentCta = withTheme(({ theme, link }) => {
  const { text, title, url } = link;
  return (
    <ContentCell
      css={css`
              margin-top: {-24,-24,,,,-120}({"step": 8, "method": "round"})px;
              text-align: center;
          `(theme)}
    >
      <Button key={title} as={Link} url={url} title={title}>
        {text}
      </Button>
    </ContentCell>
  );
});

const BasicPage = withTheme(({ theme, ...props }) => {
  const {
    pageData: { hero, content, locale, fullUrl, breadCrumbs },
  } = props.pageContext;
  const { overline, headline, subline, image } = hero || {};
  return (
    <React.Fragment>
      {!!hero && (
        <Hero
          overline={overline}
          headline={headline}
          subline={subline}
          image={image.localFile}
        />
      )}
      <ContentBox
        content={content}
        addBlocks={{
          "content-blocks.cta-box": ContentCtaBox,
          "content-blocks.cta": ContentCta,
        }}
        css={css`$my26;`(theme)}
        locale={locale}
      />

      {!!breadCrumbs && !!breadCrumbs.length && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: ldJsonBreadCrumb(breadCrumbs) }}
        />
      )}
      <SharingBar url={fullUrl} css={css`$mb26;`(theme)} />
    </React.Fragment>
  );
});

export default BasicPage;
